<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @submit="handleSubmit" :form="form">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="用户名">
              <a-input v-decorator="[
                'username',
                {
                  initialValue: queryParam.username
                }
              ]" placeholder="用户名" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="邮箱">
              <a-input v-decorator="[
                'email',
                {
                  rules: [
                    { type: 'email', message: '邮箱格式错误' },
                  ],
                  initialValue: queryParam.email
                }
              ]" placeholder="邮箱" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="电话">
              <a-input v-decorator="[
                'phone',
                {
                  initialValue: queryParam.email
                }
              ]" placeholder="电话号码" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button htmlType="submit" :loading="submitLoading" type="primary">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
              <a @click="toggleAdvanced" style="margin-left: 8px">
                {{ advanced ? '收起' : '展开' }}
                <a-icon :type="advanced ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
          <template v-if="advanced">
            <a-col :md="6" :sm="24">
              <a-form-item label="性别">
                <a-select placeholder="请选择" v-decorator="[
                  'sex',
                  {
                    initialValue: queryParam.sex
                  }
                ]">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option v-for="option in sex_type" :key="option" :value="option">{{ option }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="状态">
                <a-select placeholder="请选择" v-decorator="[
                  'status',
                  {
                    initialValue: queryParam.status
                  }
                ]">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option v-for="option in user_status_type" :key="option" :value="option">{{ option }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="省份">
                <a-input v-decorator="[
                  'province',
                  {
                    initialValue: queryParam.province
                  }
                ]" placeholder="省份" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="城市">
                <a-input v-decorator="[
                  'city',
                  {
                    initialValue: queryParam.city
                  }
                ]" placeholder="城市" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="区域">
                <a-input v-decorator="[
                  'area',
                  {
                    initialValue: queryParam.area
                  }
                ]" placeholder="区域" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="地址">
                <a-input v-decorator="[
                  'address',
                  {
                    initialValue: queryParam.address
                  }
                ]" placeholder="地址" />
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="data" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <span slot="status" slot-scope="text">
        <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
      </span>
      <span slot="create_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="goEdit(record)">查看</a>
      </span>
    </a-table>
  </a-card>
</template>
<script>

import { userList } from '@/api/user'
import merge from 'webpack-merge'

const statusMap = {
  'Active': {
    status: 'success',
    text: '激活'
  },
  'Closed': {
    status: 'default',
    text: '关闭'
  },
  'Error': {
    status: 'error',
    text: '异常'
  }
}

const columns = [
  {
    sorter: true,
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '昵称',
    dataIndex: 'nick_name'
  },
  {
    title: '邮箱',
    dataIndex: 'email'
  },
  {
    title: '电话',
    dataIndex: 'phone'
  },
  {
    sorter: true,
    title: '性别',
    dataIndex: 'sex'
  },
  {
    title: '登录次数',
    dataIndex: 'login_num',
    sorter: true
    // ellipsis: true
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    sorter: true,
    scopedSlots: { customRender: 'create_time' }
  },
  {
    title: '状态',
    sorter: true,
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  data() {
    return {
      description: '列表使用场景：后台管理中的权限管理以及角色管理，可用于基于 RBAC 设计的角色权限控制，颗粒度细到每一个操作类型。',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // form
      form: this.$form.createForm(this),
      // 查询参数
      queryParam: {
        username: this.$route.query.username,
        email: this.$route.query.email,
        phone: this.$route.query.phone,
        sex: this.$route.query.sex,
        status: this.$route.query.status,
        province: this.$route.query.province,
        city: this.$route.query.city,
        area: this.$route.query.area,
        address: this.$route.query.address
      },
      sex_type: {},
      user_status_type: {},
      data: [],
      pagination: {},
      loading: false,
      columns,
      submitLoading: false,
      tableLoading: false
    }
  },
  mounted() {
    this.fetch()
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      // 带上查询参数
      const queryParam = this.form.getFieldsValue()
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        ...queryParam
      })
    },
    fetch(params = {}) {
      this.loading = true
      new Promise((resolve, reject) => {
        userList(params).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        const data = res.data
        const pagination = { ...this.pagination }
        this.loading = false
        this.data = data.list.data
        this.sex_type = data.sex_type
        this.user_status_type = data.user_status_type
        pagination.total = data.list.total
        pagination.pageSize = data.list.per_page
        pagination.current = data.list.current_page
        this.pagination = pagination
      })
    },
    resetQuery() {
      // 重置查询条件，并重新请求
      this.form.setFieldsValue({
        username: '',
        email: '',
        phone: ''
      })
      if (this.advanced) {
        this.form.setFieldsValue({
          sex: '',
          status: '',
          province: '',
          city: '',
          area: '',
          address: ''
        })
      }
      this.$router.push({
        query: merge({}, {})
      })
      this.submitLoading = true
      this.fetch()
      this.submitLoading = false
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleSubmit(e) {
      // 重置查询条件，并重新请求
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitLoading = true
          this.fetch(values)
          this.submitLoading = false
          this.$router.push({
            query: merge(this.$route.query, values)
          })
        }
      })
    },
    goEdit(record) {
      this.$router.push({ 'name': 'ClientsDetail', query: { id: record.id } })
    }
  }
}
</script>
